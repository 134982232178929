export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67')
];

export const server_loads = [0];

export const dictionary = {
		"/(pages)": [24,[2]],
		"/(endpoints)/auth": [10],
		"/(endpoints)/blobs": [18],
		"/(app)/calendars": [~56,[5],[6]],
		"/(app)/events": [~32,[5,7],[6]],
		"/(app)/events/generations": [33,[5,7],[6]],
		"/(app)/events/invites": [~54,[5,7],[6]],
		"/(app)/events/new": [~55,[5,7],[6]],
		"/(app)/events/public": [~34,[5,7],[6]],
		"/(app)/events/[event_id]": [~35,[5,7],[6]],
		"/(app)/events/[event_id]/attendances": [38,[5,7],[6]],
		"/(app)/events/[event_id]/attendances/[attendance_id]": [~39,[5,7],[6]],
		"/(app)/events/[event_id]/attendances/[attendance_id]/accept_host": [~43,[5,7],[6]],
		"/(app)/events/[event_id]/attendances/[attendance_id]/cannot_go": [~42,[5,7],[6]],
		"/(app)/events/[event_id]/attendances/[attendance_id]/decline_host": [~44,[5,7],[6]],
		"/(app)/events/[event_id]/attendances/[attendance_id]/going": [~40,[5,7],[6]],
		"/(app)/events/[event_id]/attendances/[attendance_id]/maybe": [~41,[5,7],[6]],
		"/(app)/events/[event_id]/contact_upload_requests": [53,[5,7],[6]],
		"/(app)/events/[event_id]/duplicates": [36,[5,7],[6]],
		"/(app)/events/[event_id]/edit": [~50,[5,7],[6]],
		"/(app)/events/[event_id]/entries": [45,[5,7],[6]],
		"/(app)/events/[event_id]/entries/[entry_id]": [46,[5,7],[6]],
		"/(app)/events/[event_id]/entries/[entry_id]/broadcasts": [49,[5,7],[6]],
		"/(app)/events/[event_id]/entries/[entry_id]/reactions": [47,[5,7],[6]],
		"/(app)/events/[event_id]/entries/[entry_id]/reactions/[reaction_id]": [48,[5,7],[6]],
		"/(app)/events/[event_id]/invitations": [51,[5,7],[6]],
		"/(app)/events/[event_id]/polls/[poll_id]/votes": [37,[5,7],[6]],
		"/(app)/events/[event_id]/users": [52,[5,7],[6]],
		"/(endpoints)/google/auth": [~21],
		"/(endpoints)/google/sheet_connections": [22],
		"/(endpoints)/google/sheet_connections/[sheet_connection_id]": [23],
		"/(endpoints)/images/generate": [9],
		"/(app)/lists": [~58,[5,8],[6]],
		"/(app)/lists/new": [~64,[5,8],[6]],
		"/(app)/lists/[list_id]": [~59,[5,8],[6]],
		"/(app)/lists/[list_id]/edit": [~60,[5,8],[6]],
		"/(app)/lists/[list_id]/subscriptions": [62,[5,8],[6]],
		"/(app)/lists/[list_id]/subscriptions/[subscription_id]": [63,[5,8],[6]],
		"/(app)/lists/[list_id]/users": [61,[5,8],[6]],
		"/(endpoints)/notification_channels": [16],
		"/(endpoints)/notification_channels/[notification_channel_id]/activate": [~17],
		"/(pages)/pricing": [31,[2]],
		"/(pages)/(prose)/privacy": [30,[2,3]],
		"/(app)/profile": [~65,[5],[6]],
		"/(pages)/search": [~25,[2]],
		"/(endpoints)/spreadsheets": [19],
		"/(endpoints)/spreadsheets/imports": [20],
		"/(endpoints)/stripe/account_link": [~14],
		"/(endpoints)/stripe/accounts": [~13],
		"/(endpoints)/stripe/billing_portal": [~12],
		"/(endpoints)/stripe/checkout_sessions": [15],
		"/(endpoints)/stripe/login_link": [~11],
		"/(pages)/(prose)/terms": [27,[2,3]],
		"/(pages)/(prose)/thank-you": [~26,[2,3]],
		"/(pages)/(prose)/updates": [~28,[2,3,4]],
		"/(pages)/(prose)/updates/[slug]": [~29,[2,3,4]],
		"/(app)/users": [66,[5],[6]],
		"/(app)/users/[user_id]": [~67,[5],[6]],
		"/(app)/[...path]": [57,[5],[6]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';